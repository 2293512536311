import React from 'react'



class Useradmin extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="pick pt-100 secureof">
        <div className='gray-bg'>
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Key Features</span> of our Crypto Payment Gateway Development</h2>
          <p className="text-center">At Coinsclone, our certified Crypto payment gateway developers incorporate elite features to craft a highly potential crypto payment gateway software.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>User Features</h3>
                <ul className='mb-0'>
                <li><p>User-Friendly Interface</p></li>
                <li><p>User Approval / Rejection</p></li>
                <li><p>Real-Time Analytics</p></li>
                <li><p>API Management</p></li>
                <li><p>Customer Support </p></li>
                <li><p>Content Management</p></li>
                <li><p>Profit Management</p></li>
                <li><p>User Monitoring</p></li>
                <li><p>High-end security tools</p></li>
                <li><p>API Integration</p></li>
              </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>Admin Features</h3>
                <ul className='mb-0'>
                <li><p>Transaction Management  </p></li>
                <li><p>Multi-factor Authentication </p></li>
                <li><p>Multilingual Options </p></li>
                <li><p>Wallet Integration</p></li>
                <li><p>Market Value Updates</p></li>
                <li><p>Ticket Raising</p></li>
                <li><p>Account Customization  </p></li>
                <li><p>Automatic Payment Settlement</p></li>
                <li><p>Fiat-to-Crypto Conversion</p></li>
                <li><p>Cross-Platform Compatability</p></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Useradmin