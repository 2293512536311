import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <section className="technostack pt-100 mb-0">
      <div className="container">
        <div className="row table-content">
          <div className="col-md-12 col-lg-6 text-left">
            <h2 className="heading-h2"><span className="bluecolor">What technologies are Used in</span> Our Crypto Payment Gateway Development</h2>
            <p className="pharagraph">Our crypto payment gateway development leverages state-of-the-art technology tools and the expertise of highly skilled developers knowledgeable in advanced technology trends.
            </p>
          </div>
          <div className="col-md-12 col-lg-6">
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                alt="Technologies stock"
                className='logostck'
                width={573}
              />
          </div>
        </div>
      </div>
    </section>
    )
  }
}

export default TechStack             