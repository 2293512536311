import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'



class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container"> 
          <div className="row table-content">  
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
              <div className="innerContent">
                <h1 className="main-heading"><span className='bluecolor'>Crypto Payment Gateway</span> Development Company </h1>
                <p className="pharagraph">Kickstart your new crypto business venture with our feature-rich crypto payment gateway development solutions. Expand your global customer base, build trust, and establish a strong brand presence for your crypto payment gateway startup worldwide.</p>
                <div className="cta-banner">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptopayment/crypto-payment-gateway-development.webp"
                  alt="Cryptocurrency Payment Gateway Development Company"
                  width={743}
                  className='d-none d-lg-block'
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection