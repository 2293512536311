import React from 'react'




const WhyStart =()=> {

    return (
      <section className="whatis secureof pb-0 pt-100">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className='bluecolor'>Why Start</span> a Crypto Payment Gateway?</h2>
            <div className="text-center">
              <p className="pharagraph" >Starting a cryptocurrency payment gateway is becoming more popular because of the growing adoption of digital currencies and the rising demand for innovative payment solutions. There are over 400 million cryptocurrency users around the world and the total value of digital currencies is more than $1 trillion. As there is a surge in cryptocurrencies, businesses are keen to integrate these digital assets into their financial ecosystems. Integrating a cryptocurrency payment gateway can significantly broaden your market reach, allowing you to cater to a new segment of tech-savvy consumers who prefer using digital assets for transactions. This innovation not only positions your business as a forward-thinking leader but also differentiates it from competitors relying solely on traditional payment methods. Embracing crypto payments can enhance customer loyalty, attract international clients, and provide a competitive edge in an increasingly digital marketplace.
              </p>
            </div>
            <div className='mw850 why-card'>
                <p>Quick Global Reach</p>
                <p>Attract New Customers</p>
                <p>Increased Revenue Opportunities</p>
                <p>Generate Brand Credibility</p>
            </div>
        </div>
      </section>
    )
}

export default WhyStart